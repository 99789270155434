@import url(https://fonts.googleapis.com/css?family=Raleway:400,700,900,400italic,700italic,900italic);
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background-color: #f8f9fa;
}


.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.sub-heading{
  text-align: center;
  color: #011a32;
  font: 16px/1.25 'Raleway', sans-serif;
  font-size: 2em;     
  font-weight: 500;
}

a:hover{
  text-decoration:none;
}

.sign-box{
  width:50%;
  background-color: white;
}

.fa {
  font-size: 30px;
  width: 50px;
  height: 47px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
  color: white;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  
}

.fa-google {
  background: #dd4b39;
  
}

.fa-google:hover {
  color: #a82513;
}

.fa-phone {
  background: #45bbff;
  
}

.profile-display {
  font-size: 30px;
  width: 50px;
  height: 47px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
  color: white;
  background: #3B5998;
}



.panel-box{
  height:auto;
  width:100%;
  background-color: white;
}

.play-name {
  font-family: "Lato", sans-serif;
}


.btn1 {
  margin: 20px auto;
  border-color: #00f0b4cf;
  padding: 10px 44px;
  font-size: 36px;
  position: relative;
}
.btn1::before {
  transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: '';
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.btn1.rounded1 {
  border-radius: 50px;
}
.text-green {
  color: #00F0B5;
}

.text-green:hover {
  mix-blend-mode: difference;
}
.btn1.rounded1::before {
  border-radius: 50px;
  width: 25%;
  background: #00F0B5;
}
.btn1.rounded1:hover::before {
  background: #00F0B5;
  width: 100%;
}

.btn2 {
  margin: 20px auto;
  border-color: #ff3333;
  padding: 10px 44px;
  font-size: 36px;
  position: relative;
}
.btn2::before {
  transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: '';
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.btn2.rounded2 {
  border-radius: 50px;
}
.text-red {
  color: #ff3333;
}

.text-red:hover {
  mix-blend-mode: difference;
}
.btn2.rounded2::before {
  border-radius: 50px;
  width: 25%;
  background: #ff3333;
}
.btn2.rounded2:hover::before {
  background: #ff3333;
  width: 100%;
}

.btn3 {
  margin: 20px auto;
  border-color: #6699ff;
  padding: 10px 44px;
  font-size: 36px;
  position: relative;
  justify-content: center;
}
.btn3::before {
  transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: '';
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.btn3.rounded3 {
  border-radius: 50px;
}
.text-blue {
  color: #3377ff;
}

.text-blue:hover {
  mix-blend-mode: difference;
}
.btn3.rounded3::before {
  border-radius: 50px;
  width: 25%;
  background: #6699ff;
}
.btn3.rounded3:hover::before {
  background: #6699ff;
  width: 100%;
}

.hide {
  display: flex;
}


.fullscreen-enabled .software-background1 {
  background: black;
}

.fullscreen-enabled .shiftingVergence-background{
  background-image: url(/static/media/noisetexture.d2d2e694.png);
  width:92%;
  height:88%;
}

.fullscreen-enabled .canvas{
  width:100%;
  height:100%;
}

.fullscreen-enabled .reboundmemono-background{
  margin:10%
}

.fullscreen-enabled .white-background{
  background-color: white;
  height:100%;
  width:100%;
}

.custom-button{
  font-size:90px;
  font-family:sans-serif;
  border:none;
  background-color: white;
}

.custom-button2{
  font-size:80px;
  font-family:sans-serif;
  border:none;
  background-color: black;
}

.custom-button3{
  border:none;
  background-color: black;
}

.custom-button4{
  background-color:#3B5998;
  color:white;
}

.custom-button5{
  font-size:40px;
  font-family:sans-serif;
  border:none;
  background-color: white;
  width:3%;
}

.play-board{
  height:80%;
  width:100%;
  visibility: visible;
}

.play-board2{
  height:70%;
  width:92%;
}

.to-select{
  height:15%;
  width:92%; 
}

.to-select h1{
  font-size: 5em;
  font-family:sans-serif;
}

.pos-1{
  position: fixed;
  top:20%;
  left:25%;
}

.pos-2{
  position: fixed;
  top:20%;
  left:55%;
}

.pos-3{
  position: fixed;
  top:65%;
  left:25%;
}

.pos-4{
  position: fixed;
  top:65%;
  left:55%;
}

.pos-5{
  position: fixed;
  top:42%;
  left:40%;
}

.my-checkbox{
  color: #3B5998;
}

.blackbox{
  background-color: black;
  height:80px;
  width: 100%;
}

.redbox{
  background-color:red;
  height:80px;
}

.bluebox{
  background-color:blue;
  height:80px;
}

.select-profile{
  width:25%
}

@media (max-width: 767.98px) { 
  .sign-box{
    width:100%;
  }
  .select-profile{
    width:50%
  }
 }

 @media (max-width: 991.98px) { 
  .sign-box{
    width:100%;
  }
  .select-profile{
    width:50%
  }
  }

  #components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
  }
  
  .site-layout-sub-header-background {
    background: #fff;
  }
  
  .site-layout-background {
    background: #fff;
  }

  .radio-toolbar {
    margin: 0 0;
  }
  .radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 20%;
  }
  .radio-toolbar label {
    display: inline-block;
    background: #fff 0% 0% no-repeat padding-box;
    padding: 10px 18px;
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #fff;
    border-radius: 17px;
    margin-right: 5%;
  }
  .radio-toolbar label:hover {
    background-color: #2f67ff;
    color: #fff;
    border: 1px solid #2f67ff;
    box-shadow: 0 3px 6px #6d94ff;
  }
  .radio-toolbar input[type="radio"]:focus + label {
    border: 1px solid #0144ff;
  }
  .radio-toolbar input[type="radio"]:checked + label {
    background-color: #0144ff;
    color: #fff;
    border: 1px solid #0144ff;
  }

  .card-img-top{
    cursor: pointer;
  }
